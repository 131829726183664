import React, { useCallback } from 'react';
import { visibleToAdmin } from '@helpers/authRules.helper';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { ADMIN_DASHBOARD_ENDPOINTS } from '@screens/AdminDashboard/Root/components/Routing/endpoints';

export interface IAdminEditButtonProps {
  buildingId: string;
}

const AdminEditButton: React.FC<IAdminEditButtonProps> = (
  { buildingId }
) => {
  const Btn = useCallback(visibleToAdmin()(
    () => (
      <SmallLinkButton
        to={ADMIN_DASHBOARD_ENDPOINTS.BUILDING_EDITOR(buildingId)}
        right
      >
        Edit
      </SmallLinkButton>
    )
  ), [buildingId]);
  return <Btn />;
};

export { AdminEditButton };
