import React, { useCallback, useMemo } from 'react';
import { Modal, ModalContent } from 'semantic-ui-react';
import { IBindingCallback1, IBindingCallback2 } from '@models/Callbacks';
import { ScheduleType, schTypeKey } from '@models/domain/schedule/ScheduleType';
import { IModalProps } from '@containers/Schedules/BookingDetailsModal';
import { ScheduleStatus } from '@root/models/domain/schedule/ScheduleStatus';
import {
  IBookingDetailsForSchedulingDto, ITemplate2Amount
} from '@screens/NeedsDashboard/DropOffAndPickUp/model/BookingDetailsDto';
import { IScheduleDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/Schedule';
import { formToSchedule } from '@screens/NeedsDashboard/DropOffAndPickUp/components/EditingSchedulesSegment/ScheduleSavingModal/ScheduleForm/utils';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleResponse';
import { schedulesWithoutGiven } from '@helpers/spaces.helper';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IScheduleSpaceNoteDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleSpaceNoteDto';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import ScheduleForm from './ScheduleForm';
import styles from './styles.module.scss';

export interface IScheduleSavingModalProps extends IModalProps {
  saveLoading: boolean;
  scheduleLoading?: boolean;
  modalProps: ISavingModalProps | undefined;
  createdSchedules: IScheduleResponseDto[];
  bookingDetails: IBookingDetailsForSchedulingDto;
  availableSpaces?: ISpaceWithAvailability[];
  filledSpaces?: IScheduleSpaceNoteDto[];
  updateSpaces?: IBindingCallback2<ITemplate2Amount[], IScheduleResponseDto[]>;
}

export interface ISavingModalProps {
  initialData?: IScheduleResponseDto;
  save: IBindingCallback1<IScheduleDto>;
  cancelSchedule: IBindingCallback1<string>;
}

const ScheduleSavingModal: React.FC<IScheduleSavingModalProps> = (
  { modalProps, onClose, saveLoading, scheduleLoading, bookingDetails, createdSchedules,
    availableSpaces, filledSpaces, updateSpaces }
) => {
  const { initialData: editedSchedule, cancelSchedule, save } = modalProps || {};

  const createdSchedulesWithoutEdited = useMemo(
    () => schedulesWithoutGiven(createdSchedules, editedSchedule?.id),
    [createdSchedules, editedSchedule]
  );
  const createdDropOffs = useMemo(
    () => createdSchedulesWithoutEdited?.filter(sch => sch.type === schTypeKey(ScheduleType.DROP_OFF)),
    [createdSchedulesWithoutEdited]
  );
  const createdPickUps = useMemo(
    () => createdSchedulesWithoutEdited?.filter(sch => sch.type === schTypeKey(ScheduleType.PICK_UP)),
    [createdSchedulesWithoutEdited]
  );

  const bookedT2A = bookingDetails.templatesToAmounts;

  const updateAvailableSpaces = (newDate: Date) => {
    if (newDate) {
      updateSpaces(bookedT2A, createdSchedulesWithoutEdited);
    }
  };

  const handleCancelSchedule = useCallback(() => {
    cancelSchedule(editedSchedule?.id);
    onClose();
  }, [cancelSchedule, editedSchedule, onClose]);

  const handleSaveSchedule = useCallback(formData => save({
    ...formToSchedule(formData),
    id: editedSchedule?.id,
    bookingId: bookingDetails.booking.id
  }), [bookingDetails.booking.id, editedSchedule, save]);

  const isNew = !editedSchedule?.id;
  const existsAnotherActiveDropOff = !!createdDropOffs?.filter(
    sch => [ScheduleStatus.PENDING, ScheduleStatus.ACCEPTED].includes(sch.status)
  )?.length;
  const existsAnotherActivePickUp = !!createdPickUps?.filter(
    sch => [ScheduleStatus.PENDING, ScheduleStatus.ACCEPTED].includes(sch.status)
  )?.length;

  return (
    <Modal onClose={onClose} open={editedSchedule !== undefined} closeIcon size="tiny">
      {editedSchedule !== undefined && (
        <>
          <ModalHeaderWrapper>
            {!isNew ? (
              <div className={styles.modal_header}>
                <Header3 className={styles.margin_right}>Schedule details</Header3>
              </div>
            ) : (
              <div>
                <Header3>Create schedule</Header3>
              </div>
            )}
          </ModalHeaderWrapper>
          <ModalContent>
            <ScheduleForm
              save={handleSaveSchedule}
              saveLoading={saveLoading}
              initialValuesLoading={scheduleLoading}
              availableSpaces={availableSpaces}
              updateAvailableSpaces={updateAvailableSpaces}
              editedSchedule={editedSchedule}
              bookingDetails={bookingDetails}
              existsAnotherActiveDropOff={existsAnotherActiveDropOff}
              existsAnotherActivePickUp={existsAnotherActivePickUp}
              filledSpaces={filledSpaces}
              templatesWithSpaces={bookedT2A}
              onCancelSchedule={handleCancelSchedule}
            />
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

export default ScheduleSavingModal;
