import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import { Form, FormGroup } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'lodash';
import { ScheduleType, scheduleTypeOptions, schTypeKey } from '@models/domain/schedule/ScheduleType';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import { nilAreEqual } from '@screens/NeedsDashboard/Account/components/AccountDetailsForm';
import SemanticDatePicker from '@components/SemanticDatePicker';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ISpaceToAmount } from '@screens/BuildingDetails/model/BookingRequest';
import { ScheduleStatus } from '@models/domain/schedule/ScheduleStatus';
import {
  IBookingDetailsForSchedulingDto,
  ITemplate2Amount
} from '@screens/NeedsDashboard/DropOffAndPickUp/model/BookingDetailsDto';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleResponse';
import SpacePickUpBlock from '@screens/NeedsDashboard/DropOffAndPickUp/components/SpacePickUpBlock';
import { IScheduleSpaceNoteDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleSpaceNoteDto';
import SpaceDropOffBlock from '@screens/NeedsDashboard/DropOffAndPickUp/components/SpaceDropOffBlock';
import { IScheduleSpaceRequest } from '@screens/NeedsDashboard/DropOffAndPickUp/model/Schedule';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import TextAreaWrapper from '@components/NewDesign/TextArea';
import DividingLine from '@components/NewDesign/DividingLine';
import SubHeader2 from '@components/NewDesign/Typography/SubHeaders/SubHeader2';
import SelectWrapper from '@components/NewDesign/Select';
import BodyText2 from '@components/NewDesign/Typography/BodyText/BodyText2';
import SimpleTimePicker from '@components/NewDesign/TimePicker';
import { scheduleToForm, toUTCTime } from './utils';
import ExistingScheduleInfo from './ExistingScheduleInfo';
import { CancelScheduleButtonWithModal } from './CancelScheduleButtonWithModal';
import styles from './styles.module.scss';

export interface IScheduleFormProps {
  save: IBindingCallback1<IFormValues>;
  saveLoading: boolean;
  editedSchedule?: IScheduleResponseDto;
  initialValuesLoading: boolean;
  availableSpaces: ISpaceWithAvailability[];
  updateAvailableSpaces: IBindingCallback1<Date>;
  className?: string;
  bookingDetails?: IBookingDetailsForSchedulingDto;
  existsAnotherActiveDropOff?: boolean;
  existsAnotherActivePickUp?: boolean;
  filledSpaces: IScheduleSpaceNoteDto[];
  templatesWithSpaces: ITemplate2Amount[];
  onCancelSchedule: IBindingAction;
}

export interface IFormValues {
  time: string;
  date: Date;
  type: ScheduleType;
  status: ScheduleStatus;
  address1: string;
  address2: string;
  city: string;
  state: string;
  notes: string;
  allSpaces: boolean;
  spaces: IScheduleSpaceRequest[];
}

const defaultFormValues: IFormValues = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  type: undefined,
  spaces: [],
  allSpaces: false,
  status: undefined,
  notes: '',
  time: moment().add(1, 'hours').format('HH:mm a'),
  date: moment().add(1, 'hours').toDate()
};

const calculateNewRequestsAfterDropOffUpdate = (
  spaces: IScheduleSpaceRequest[], availableSpaces: ISpaceWithAvailability[],
  templatesWithSpaces: ITemplate2Amount[], templateId: string, spacesNotes: string[]
) => {
  const currentTemplateWithSpaces = templatesWithSpaces
    .find(t => t.spaceTemplate.id === templateId);
  const currentTemplateSpaceIds = currentTemplateWithSpaces.spaces.map(s => s.id);
  const spacesWithoutCurrentTemplate = spaces
    .filter(space => !currentTemplateSpaceIds.includes(space.spaceId));
  const currentTemplateWithAvailableSpaceIds = availableSpaces
    .find(s => s.id === templateId);

  return [
    ...spacesWithoutCurrentTemplate,
    ...spacesNotes.map((note, i) => ({
      spaceId: currentTemplateWithAvailableSpaceIds.spaceIdsAvailable[i],
      note
    }))
  ];
};

const ScheduleForm: React.FC<IScheduleFormProps> = (
  {
    saveLoading, save, initialValuesLoading, className, editedSchedule,
    availableSpaces, updateAvailableSpaces, bookingDetails, existsAnotherActiveDropOff,
    existsAnotherActivePickUp, filledSpaces, templatesWithSpaces, onCancelSchedule
  }
) => {
  const initialValues = useMemo(() => (
    editedSchedule?.id ? scheduleToForm(editedSchedule) : defaultFormValues
  ), [editedSchedule]);
  const isEditing = useMemo(() => (editedSchedule?.id), [editedSchedule]);
  const ableToUpdate = useMemo(() => (editedSchedule?.status === ScheduleStatus.PENDING), [editedSchedule]);
  const [values, setValues] = useState<IFormValues>(initialValues);

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);
  useEffect(() => {
    if (values.date && values.time && values.type) {
      updateAvailableSpaces(toUTCTime(values.date, values.time));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.time, values.date, values.type]);

  const handleDropOffSelect = useCallback((spaceModel: ISpaceToAmount) => {
    setValues(prev => (
      {
        ...prev,
        spaces: calculateNewRequestsAfterDropOffUpdate(
          prev.spaces, availableSpaces, templatesWithSpaces, spaceModel.spaceTemplateId, spaceModel.spacesNotes
        )
      }));
  }, [availableSpaces, templatesWithSpaces]);

  const handlePickUpUpdate = useCallback((request: IScheduleSpaceRequest) => {
    setValues(prev => (
      {
        ...prev,
        spaces: [...prev.spaces.filter(space => space.spaceId !== request.spaceId), request]
      }));
  }, []);

  const handlePickUpDelete = useCallback((spaceId: string) => {
    setValues(prev => (
      {
        ...prev,
        spaces: prev.spaces.filter(space => space.spaceId !== spaceId)
      }));
  }, [setValues]);

  const formDisabled = values as any === {}
    || _.isEqualWith(values, initialValues, nilAreEqual)
    || !values.spaces.length;

  const bookingEndDate = bookingDetails.booking.endingDate;
  const bookingEndMoment = bookingEndDate && moment(bookingEndDate, 'YYYY-MM-DD');

  const conflict = (values.type === schTypeKey(ScheduleType.PICK_UP) && existsAnotherActivePickUp)
    || (values.type === schTypeKey(ScheduleType.DROP_OFF) && existsAnotherActiveDropOff);

  const formVisible = (!isEditing || ableToUpdate) && !conflict;
  const isCancelScheduleButtonShown = !!editedSchedule?.id;

  return (
    <Form
      className={`${styles.form} ${className ?? ''}`}
      onSubmit={() => {
        if (!formDisabled) save(values);
      }}
      loading={initialValuesLoading}
    >
      <>
        {isEditing ? (
          <ExistingScheduleInfo formValues={editedSchedule} />
        ) : (
          <SelectWrapper
            options={scheduleTypeOptions}
            placeholder="Drop off or pick up?"
            value={values?.type}
            onChange={(event, { value }) => {
              setValues(prev => ({ ...prev, type: value as any, spaces: [] }));
            }}
          />
        )}
        {conflict && (
          <div className={styles.exists}>
            <BodyText2>
              There is already an active schedule with such type.
            </BodyText2>
          </div>
        )}
        {formVisible && values.type && (
          <>
            <FormGroup widths="equal" className={styles.date_pickers}>
              <SimpleTimePicker
                value={values.time ? moment(values.time, 'HH:mm') : undefined}
                onChange={val => (val
                  ? setValues(prev => ({ ...prev, time: `${val.hour()}:${val.minute()}` }))
                  : setValues(prev => ({ ...prev, time: '' }))
                )}
                label="Pick time"
              />
              <SemanticDatePicker
                semanticProps={{ label: 'Pick date' }}
                value={{ startingDate: values.date }}
                onChange={({ startingDate }) => setValues(prev => ({ ...prev, date: startingDate }))}
                numberOfMonths={1}
                singleDate
                disabledDays={
                  date => DateUtils.isPastDay(date)
                    || (bookingEndMoment && DateUtils.isDayAfter(date, bookingEndMoment.toDate()))
                }
              />
            </FormGroup>
            <TextAreaWrapper
              placeholder="Additional notes"
              value={values.notes || ''}
              onChange={(ev, { value }) => setValues(prev => ({ ...prev, notes: value as string }))}
            />
            <DividingLine light marginTop marginBottom />
            <div className="field">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <SubHeader2>Spaces selection</SubHeader2>
              </label>
            </div>
            {values.type === schTypeKey(ScheduleType.DROP_OFF) && (
              <SpaceDropOffBlock
                availableSpaces={availableSpaces}
                chosenSpaces={values.spaces}
                handleUpdate={handleDropOffSelect}
                initialRequests={values.spaces}
              />
            )}
            {values.type === schTypeKey(ScheduleType.PICK_UP) && (
              <SpacePickUpBlock
                filledSpaces={filledSpaces}
                spaceTemplates={templatesWithSpaces.map(t => t.spaceTemplate)}
                handleUpdate={handlePickUpUpdate}
                handleDelete={handlePickUpDelete}
                initialRequests={values.spaces}
              />
            )}
          </>
        )}
      </>
      <div className={styles.buttons_container}>
        {isCancelScheduleButtonShown && (
          <div className={styles.cancel_button}>
            <CancelScheduleButtonWithModal
              editedSchedule={editedSchedule}
              onCancelSchedule={onCancelSchedule}
            />
          </div>
        )}
        {formVisible && (
          <div className={styles.submit_button}>
            <PrimaryButton
              type="submit"
              content="Save"
              loading={saveLoading}
              disabled={formDisabled}
            />
          </div>
        )}
      </div>
    </Form>
  );
};

export default ScheduleForm;
