import React from 'react';
import classNames from 'classnames';
import Header3 from '@components/NewDesign/Typography/Headers/Header3';
import DividingLine from '@components/NewDesign/DividingLine';
import AdditionalServicesOrder from '@components/NewDesign/AdditionalServicesOrder';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { IBindingCallback1 } from '@models/Callbacks';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import { IBriefBookingDto } from '@models/domain/Booking';
import { BookingStatus } from '@models/domain/BookingStatus';
import Caption2 from '@components/NewDesign/Typography/Caption/Caption2';
import styles from './styles.module.scss';

export interface IAdditionalServicesReceiptSectionProps {
  booking?: IBriefBookingDto;
  buildingId: string;
  className?: string;
  servicesList: IAdditionalServiceWithAvailability[];
  servicesListLoading: boolean;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
  createAdditionalServicesOrder: IBindingCallback1<IMakeOrderRequest>;
}

const AdditionalServicesSection: React.FC<IAdditionalServicesReceiptSectionProps> = (
  {
    booking, buildingId, className, servicesList, servicesListLoading,
    loadAdditionalServicesList, createAdditionalServicesOrder
  }
) => (
  <div className={classNames(styles.additional_services, className)}>
    <DividingLine light marginBottom />
    <Header3 className={styles.additional_services_header}>
      Additional services
    </Header3>
    {booking && buildingId && (
      <>
        {booking.status === BookingStatus.CONFIRMED
          ? (
            <AdditionalServicesOrder
              buildingId={buildingId}
              booking={booking}
              servicesList={servicesList}
              servicesListLoading={servicesListLoading}
              loadAdditionalServicesList={loadAdditionalServicesList}
              createAdditionalServicesOrder={createAdditionalServicesOrder}
            />
          )
          : (
            <Caption2>
              There are no additional services available for this booking, since it is not active.
              <br />
              Create new booking and try again.
            </Caption2>
          )}
      </>
    )}
  </div>
);

export default AdditionalServicesSection;
