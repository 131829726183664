import React, { useState } from 'react';
import { Label } from 'semantic-ui-react';
import BookingsTable from '@screens/HavesDashboard/DropOffAndPickUp/containers/BookingsTable';
import ConnectedBookingDetailsModal
  from '@screens/HavesDashboard/DropOffAndPickUp/containers/ConnectedBookingDetailsModal';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/BookingDetailsDto';
import { IBookingDetailsForSchedulingWithPerson } from '@screens/HavesDashboard/DropOffAndPickUp/model/BookingDetailsForSchedulingWithPerson';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import styles from './styles.module.scss';

export const DropOffAndPickUp: React.FC = () => {
  const [expandedBooking, setExpandedBooking] = useState<IBookingDetailsForSchedulingDto>();
  return (
    <DashboardPageWrapper title="Drop-Off/Pick-Up Scheduling">
      <DashboardSectionWrapper>
        <BookingsTable
          tableProps={(bookings: IBookingDetailsForSchedulingWithPerson[]) => ({
            titles: ['', 'Building Name', 'Space Details', 'Qty', 'Person'],
            rows: bookings.map(b => ({
              id: b.booking.id,
              cells: [
                {
                  content: b.needsReview && <Label circular size="mini" color="orange" empty />,
                  props: { className: styles.narrow_cell }
                },
                { content: (
                  <TableImportantContent disableTranslation>{b.building.buildingName}</TableImportantContent>
                ) },
                {
                  content: b.templatesToAmounts.length > 1
                    ? `${b.templatesToAmounts.length} kinds of spaces`
                    : b.templatesToAmounts[0].spaceTemplate.alias
                },
                {
                  content: b.templatesToAmounts.map(t2a => t2a.spaces.length)
                    .reduce((prev, curr) => prev + curr).toString()
                },
                { content: `${b.booking.personBooked.firstName} ${b.booking.personBooked.lastName}` }
              ],
              rowProps: {
                onClick: () => setExpandedBooking(b),
                warning: b.needsReview
              }
            }))
          })}
        />
        <ConnectedBookingDetailsModal expandedBooking={expandedBooking} setExpandedBooking={setExpandedBooking} />
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

export default DropOffAndPickUp;
