import React from 'react';
import { LabelGroup, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import moment from 'moment';
import { IBindingCallback1 } from '@models/Callbacks';
import { colorOfScheduleType, iconOfScheduleType } from '@models/domain/schedule/ScheduleType';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleResponse';
import { DATE_FORMAT } from '@helpers/date.helper';
import ModalHeaderWrapper from '@components/NewDesign/ModalHeaderWrapper';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import LabelWrapper from '@components/NewDesign/LabelWrapper';
import styles from './styles.module.scss';

export interface IReviewScheduleModalProps {
  expandedScheduleData: IScheduleResponseDto;
  reviewSchedule: (approved: boolean) => void;
  reviewLoading: boolean;
  setExpandedScheduleData: IBindingCallback1<IScheduleResponseDto>;
}

const ReviewScheduleModal: React.FC<IReviewScheduleModalProps> = (
  { reviewSchedule, expandedScheduleData, reviewLoading, setExpandedScheduleData }
) => (
  <Modal
    open={expandedScheduleData !== undefined}
    onClose={() => setExpandedScheduleData(undefined)}
    size="small"
    closeIcon
  >
    <ModalHeaderWrapper>Review schedule</ModalHeaderWrapper>
    {expandedScheduleData && (
      <ModalContent>
        <LabelGroupWrapper
          wrapper={LabelGroup}
          title="Latest edit"
          value={(
            <LabelWrapper>
              {moment(expandedScheduleData.updatedAt).format(`${DATE_FORMAT}, HH:mm A`)}
            </LabelWrapper>
          )}
        />
        <LabelGroupWrapper
          wrapper={LabelGroup}
          title="Scheduled time"
          value={(
            <LabelWrapper>
              {moment(expandedScheduleData.scheduledTime).format(`ddd ${DATE_FORMAT}, HH:mm A`)}
            </LabelWrapper>
          )}
        />
        {expandedScheduleData.notes && (
          <LabelGroupWrapper
            wrapper={LabelGroup}
            title="Note"
            value={(
              <LabelWrapper>
                {expandedScheduleData.notes}
              </LabelWrapper>
            )}
          />
        )}
        <LabelGroupWrapper
          wrapper={LabelGroup}
          title="Type"
          value={(
            <LabelWrapper
              color={colorOfScheduleType(expandedScheduleData.type)}
              icon={iconOfScheduleType(expandedScheduleData.type)}
              content={expandedScheduleData.type}
            />
          )}
        />
        <LabelGroupWrapper
          wrapper={LabelGroup}
          title="Booking"
          value={(
            <LabelWrapper content={expandedScheduleData.bookingId} />
          )}
        />
      </ModalContent>
    )}
    <ModalActions>
      <div className={styles.buttons}>
        <div>
          <PrimaryButton
            onClick={() => reviewSchedule(true)}
            loading={reviewLoading}
            content="Accept"
          />
        </div>
        <div>
          <DangerButton
            onClick={() => reviewSchedule(false)}
            loading={reviewLoading}
            content="Reject"
          />
        </div>
      </div>
    </ModalActions>
  </Modal>
);

export default ReviewScheduleModal;
