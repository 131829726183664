import React, { useCallback, useState } from 'react';
import { LabelGroup } from 'semantic-ui-react';
import moment from 'moment';
import { DateUtils } from 'react-day-picker';
import { IBriefBookingDto } from '@models/domain/Booking';
import { BookingStatus } from '@models/domain/BookingStatus';
import { Role } from '@screens/Authorization/models/Roles';
import CancelBookingModal from '@containers/Schedules/BookingDetailsModal/BookingDetails/CancelBookingModal';
import { DATE_FORMAT } from '@helpers/date.helper';
import { IBindingCallback1 } from '@models/Callbacks';
import { IBookingCancellationRequest } from '@screens/NeedsDashboard/DropOffAndPickUp/model/BookingCancellationRequest';
import BookingStatusLabel from '@components/BookingStatusLabel';
import SubHeader1 from '@components/NewDesign/Typography/SubHeaders/SubHeader1';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import LabelGroupWrapper from '@components/NewDesign/LabelGroupWrapper';
import PopupWrapper from '@components/NewDesign/PopupWrapper';
import styles from './styles.module.scss';

export interface IBookingDetailsProps<B extends IBriefBookingDto> {
  booking: B;
  currentRole?: Role;
  onCancellationSubmit?: IBindingCallback1<IBookingCancellationRequest>;
  cancellationLoading?: boolean;
}

function BookingDetails<BookingDto extends IBriefBookingDto>(
  { booking, currentRole, onCancellationSubmit, cancellationLoading }: IBookingDetailsProps<BookingDto>
) {
  const [cancelModalShown, setCancelModalShown] = useState<boolean>(false);

  const now = moment();
  const bookingStart = moment(booking?.startingDate);
  const currMonth = DateUtils.isDayBefore(now.toDate(), bookingStart.toDate()) ? bookingStart : now;
  const firstDayNextMonth = currMonth.add(1, 'months').startOf('month').toDate();
  const lastBookingDay = booking?.endingDate && moment(booking?.endingDate).toDate();

  const ableToUpdate = currentRole === Role.NEED && booking?.status === BookingStatus.CONFIRMED;
  const noDaysToCancel = firstDayNextMonth
    && lastBookingDay
    && DateUtils.isDayBefore(lastBookingDay, firstDayNextMonth);

  const handleSubmitDate = useCallback((cancellationDate: Date) => {
    if (onCancellationSubmit && booking) {
      onCancellationSubmit({ bookingId: booking.id, cancellationDate });
    }
  }, [onCancellationSubmit, booking]);

  return (
    <div className={styles.container}>
      <div>
        <SubHeader1 className={styles.subheader}>Booking</SubHeader1>
        <LabelGroupWrapper
          wrapper={LabelGroup}
          title="Status"
          value={(<BookingStatusLabel status={booking.status} createdAt={booking.createdAt} />)}
        />
        <LabelGroupWrapper
          wrapper={LabelGroup}
          title="From"
          value={moment(booking.startingDate).format(DATE_FORMAT)}
        />
        {booking.endingDate && (
          <LabelGroupWrapper
            wrapper={LabelGroup}
            title="To"
            value={moment(booking.endingDate).format(DATE_FORMAT)}
          />
        )}
      </div>
      {ableToUpdate && (
        <PopupWrapper
          trigger={(
            <div>
              <DangerButton
                content="Edit"
                onClick={() => setCancelModalShown(true)}
                disabled={noDaysToCancel}
                loading={cancellationLoading}
              />
            </div>
          )}
          content="You have already paid for the whole booking"
          disabled={!noDaysToCancel}
        />
      )}
      <CancelBookingModal
        open={cancelModalShown}
        onClose={() => setCancelModalShown(false)}
        firstDayNextMonth={firstDayNextMonth}
        lastBookingDay={lastBookingDay}
        onSubmitDate={handleSubmitDate}
      />
    </div>
  );
}

export default BookingDetails;
