import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Dashboard from '@screens/NeedsDashboard/Dashboard/containers/DashboardPage';
import Account from '@screens/NeedsDashboard/Account/containers/AccountPage';
import OptionalBuildingRenderer from '@components/OptionalBuildingRenderer';
import BookedSpaces from '@screens/NeedsDashboard/BookedSpaces/containers/BookedSpacesPage';
import Transactions from '@screens/NeedsDashboard/Transactions/containers/TransactionsPage';
import { NEEDS_DASHBOARD_ENDPOINTS } from './endpoints';
import Favorites from '@screens/NeedsDashboard/Favorites/containers/FavoritesPage';
import DropOffAndPickUp from '@screens/NeedsDashboard/DropOffAndPickUp/containers/DropOffAndPickUpPage';
import Orders from '@screens/NeedsDashboard/Orders/containers/OrdersPage';
import { OrderDetailsPage } from '@screens/NeedsDashboard/Orders/containers/OrderDetailsPage';
import AppRoute from '@containers/AppRoute';
import NotFound from '@components/404NotFound';
import CreditCardConfigPage from '@screens/NeedsDashboard/CreditCardConfig/containers';
import MessageCenter from '@screens/NeedsDashboard/MessageCenter/containers/MessageCenterPage';

export const withOptionalBuilding = Component => props => (
  <OptionalBuildingRenderer>
    <Component {...props} />
  </OptionalBuildingRenderer>
);

const NeedsDashboardRouting: React.FC = () => {
  const { DASHBOARD, ACCOUNT, DROP_PICK, ORDERS, ORDER_DETAILS, FAVORITES, SPACES,
    TRANSACTIONS, CARDS_CONFIG, MESSAGES, LIST_YOUR_SPACE } = NEEDS_DASHBOARD_ENDPOINTS;
  return (
    <Switch>
      <AppRoute lightBlue exact path={DASHBOARD} component={withOptionalBuilding(Dashboard)} />
      <AppRoute lightBlue exact path={ACCOUNT} component={Account} />
      <AppRoute lightBlue exact path={MESSAGES} component={MessageCenter} />
      <AppRoute lightBlue exact path={FAVORITES} component={withOptionalBuilding(Favorites)} />
      <AppRoute lightBlue exact path={SPACES} component={withOptionalBuilding(BookedSpaces)} />
      <AppRoute lightBlue exact path={TRANSACTIONS} component={Transactions} />
      <AppRoute lightBlue exact path={DROP_PICK} component={DropOffAndPickUp} />
      <AppRoute lightBlue exact path={ORDERS} component={Orders} />
      <AppRoute lightBlue exact path={ORDER_DETAILS(':id')} component={OrderDetailsPage} />
      <AppRoute lightBlue exact path={CARDS_CONFIG} component={CreditCardConfigPage} />
      <AppRoute lightBlue exact path={LIST_YOUR_SPACE} component={() => <Redirect to={SPACES} />} />
      <AppRoute lightBlue component={NotFound} />
    </Switch>
  );
};

export default NeedsDashboardRouting;
