import React, { useCallback, useState } from 'react';
import DangerButton from '@components/NewDesign/Button/DangerButton';
import { ScheduleStatus } from '@models/domain/schedule/ScheduleStatus';
import ConfirmModal from '@components/NewDesign/ConfirmModal';
import { IScheduleResponseDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleResponse';
import { IBindingAction } from '@models/Callbacks';

type Props = {
  editedSchedule: IScheduleResponseDto;
  onCancelSchedule: IBindingAction;
}

const CancelScheduleButtonWithModal = ({ editedSchedule, onCancelSchedule }: Props) => {
  const [cancelConfirmationOpen, setCancelConfirmationOpen] = useState(false);

  const handleOpenConfirmationModal = () => setCancelConfirmationOpen(true);
  const handleCloseConfirmationModal = useCallback(() => setCancelConfirmationOpen(false), []);

  const handleCancelSchedule = useCallback(() => {
    handleCloseConfirmationModal();
    onCancelSchedule();
  }, [handleCloseConfirmationModal, onCancelSchedule]);

  return (
    <>
      <DangerButton
        content="Cancel schedule"
        type="button"
        onClick={handleOpenConfirmationModal}
        disabled={
          editedSchedule?.status !== ScheduleStatus.PENDING
          && editedSchedule?.status !== ScheduleStatus.ACCEPTED
        }
      />
      <ConfirmModal
        open={cancelConfirmationOpen}
        onCancel={handleCloseConfirmationModal}
        onConfirm={handleCancelSchedule}
        header="Cancel the schedule"
      />
    </>
  );
};

export { CancelScheduleButtonWithModal };
