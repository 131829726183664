import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import {
  createOrderReceiptAdditionalServicesOrderRoutine,
  fetchServiceOrderReceiptDataRoutine,
  loadOrderReceiptAdditionalServicesWithAvailabilityRoutine
} from '@screens/ReceiptPage/routines';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractLoadOrderReceiptAdditionalServicesWithAvailabilityLoading,
  extractOrderReceiptAdditionalServicesWithAvailability,
  extractServiceOrder,
  extractServiceOrderReceiptBuilding,
  extractServiceOrderReceiptLoading,
  extractTransaction
} from '@screens/ReceiptPage/reducers';
import Header2 from '@components/NewDesign/Typography/Headers/Header2';
import DividingLine from '@components/NewDesign/DividingLine';
import ScrollToTopOnMount from '@components/ScrollToTop';
import { toPrice } from '@helpers/price.helper';
import { IPriceWithCurrency } from '@models/domain/Booking';
import { IOrderWithServicesDto } from '@screens/ServiceOrderCheckout/model/ServiceOrderCheckout';
import OrderedServicesSummary
  from '@screens/ServiceOrderCheckout/components/ServiceOrderDetails/OrderedServicesSummary';
import ReceiptSection from '@screens/ReceiptPage/components/ReceiptSection';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import AdditionalServicesSection from '@components/NewDesign/AdditionalServicesSection';
import useIsMount from '@root/hooks/use-is-mount';
import { IServiceOrderTransactionDto } from '@screens/ReceiptPage/model/ServiceOrderReceipt';
import styles from './styles.module.scss';

export interface IServiceOrderReceiptPageProps extends IState, IActions {
}

interface IState {
  transaction: IServiceOrderTransactionDto;
  building: IBuildingForDisplaying;
  orderWithServices: IOrderWithServicesDto;
  loading: boolean;
  servicesList: IAdditionalServiceWithAvailability[];
  servicesListLoading: boolean;
}

interface IActions {
  fetchReceiptData: IBindingCallback1<string>;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
  createAdditionalServicesOrder: IBindingCallback1<IMakeOrderRequest>;
}

const ServiceOrderReceiptPage: React.FC<IServiceOrderReceiptPageProps> = (
  {
    transaction, building, orderWithServices,
    fetchReceiptData, loading, servicesList, servicesListLoading, loadAdditionalServicesList,
    createAdditionalServicesOrder
  }
) => {
  const { id } = useParams<{ id: string }>();

  useEffect(
    () => {
      fetchReceiptData(id);
    },
    [fetchReceiptData, id]
  );

  const priceToPriceWithCurrency = useCallback(
    (amount: number): IPriceWithCurrency => ({ amount, currency: orderWithServices?.order.currency }),
    [orderWithServices]
  );

  const getServicesSummaryComponent = () => (
    <OrderedServicesSummary
      services={orderWithServices?.services}
      order={orderWithServices?.order}
    />
  );

  const isMount = useIsMount();

  return (
    <>
      <ScrollToTopOnMount />
      {isMount || loading || !orderWithServices || !transaction ? (
        <Loader active inline="centered" />
      ) : (
        <div className={`content_wrapper ${styles.container}`}>
          <Header2 center marginTop marginBottom>Receipt</Header2>
          <DividingLine />
          <div className={styles.content}>
            <ReceiptSection
              transactionId={transaction.id}
              transactionDateTime={transaction.dateTime}
              building={building}
              summaryComponent={getServicesSummaryComponent}
              notes={orderWithServices.order.note}
              totalPriceLabel={toPrice(priceToPriceWithCurrency(transaction.price.amount))}
              creditCard={transaction.creditCard}
            />
            <AdditionalServicesSection
              booking={orderWithServices?.booking}
              buildingId={building.id}
              className={styles.servicesWrapper}
              servicesList={servicesList}
              servicesListLoading={servicesListLoading}
              loadAdditionalServicesList={loadAdditionalServicesList}
              createAdditionalServicesOrder={createAdditionalServicesOrder}
            />
          </div>
          <DividingLine />
        </div>
      )}
    </>
  );
};

const mapStateToProps: (state) => IState = state => ({
  transaction: extractTransaction(state),
  building: extractServiceOrderReceiptBuilding(state),
  orderWithServices: extractServiceOrder(state),
  loading: extractServiceOrderReceiptLoading(state),
  servicesList: extractOrderReceiptAdditionalServicesWithAvailability(state),
  servicesListLoading: extractLoadOrderReceiptAdditionalServicesWithAvailabilityLoading(state)
});

const mapDispatchToProps: IActions = {
  fetchReceiptData: fetchServiceOrderReceiptDataRoutine,
  loadAdditionalServicesList: loadOrderReceiptAdditionalServicesWithAvailabilityRoutine,
  createAdditionalServicesOrder: createOrderReceiptAdditionalServicesOrderRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOrderReceiptPage);
