import React from 'react';
import { ITransaction } from '@models/domain/Transaction';
import { IInformationSectionProps, InformationSection } from '@components/InformationSection';
import { NEEDS_DASHBOARD_ENDPOINTS } from '@screens/NeedsDashboard/Root/components/Routing/endpoints';
import NoDataContainer from '@components/NoDataContainer';
import TableWrapper from '@components/NewDesign/Table/TableWrapper';
import GoToButton from '@components/NewDesign/Button/GoToButton';

export interface IInformationSectionWrapperProps extends IInformationSectionProps<ITransaction> {
  itemToElement: (item: ITransaction, index: number) => JSX.Element;
}

const InformationSectionWrapper: React.FC<IInformationSectionWrapperProps> = (
  {
    itemToElement,
    placeholderClassName,
    ...props
  }
) => (
  <InformationSection
    {...props}
    hideTitle={{ ...props }.hideTitle}
    renderItem={itemToElement}
    itemsWrapper={TableWrapper}
    itemsWrapperProps={{
      titles: ['Paid?', 'Scheduled On', 'Paid on', 'Amount', 'Type']
    }}
    noItemsPlaceholder={() => (
      <NoDataContainer message="You do not have any transactions yet" className={placeholderClassName} />
    )}
    loadMoreComponent={() => (
      <GoToButton
        to={NEEDS_DASHBOARD_ENDPOINTS.TRANSACTIONS}
      >
        GO TO TRANSACTIONS
      </GoToButton>
    )}
  />
);

export default InformationSectionWrapper;
