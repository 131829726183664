import { IScheduleResponseDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleResponse';
import { IScheduleSpaceNoteDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/ScheduleSpaceNoteDto';
import { ScheduleType, schTypeKey } from '@models/domain/schedule/ScheduleType';
import {
  ITemplate2Amount,
  t2aToSpaceWithAvailability
} from '@screens/NeedsDashboard/DropOffAndPickUp/model/BookingDetailsDto';
import { ISpaceWithAvailability } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { ScheduleStatus } from '@models/domain/schedule/ScheduleStatus';

export const schedulesWithoutGiven = (
  schedules: IScheduleResponseDto[], id: string
): IScheduleResponseDto[] => schedules?.filter(sch => sch.id !== id);

export const getPastCompleted = (list: IScheduleResponseDto[]) => (
  list
    .filter(sch => sch.status === ScheduleStatus.COMPLETED)
);

export const calcFilledSpaces = (schedules: IScheduleResponseDto[]): IScheduleSpaceNoteDto[] => {
  let filledSpaces = [] as IScheduleSpaceNoteDto[];
  getPastCompleted(schedules)
    .reverse()
    .forEach(sch => {
      switch (sch.type) {
        case schTypeKey(ScheduleType.DROP_OFF):
          sch.spaces.forEach(space => filledSpaces.push(space));
          break;
        case schTypeKey(ScheduleType.PICK_UP):
          sch.spaces.forEach(space => { filledSpaces = filledSpaces.filter(s => s.id !== space.id); });
          break;
        default:
          throw new Error();
      }
    });
  return filledSpaces;
};

export const calcEmptySpaces = (
  bookedT2A: ITemplate2Amount[], schedules: IScheduleResponseDto[]
): ISpaceWithAvailability[] => {
  let emptySpaceIds = bookedT2A.flatMap(t => t.spaces).map(s => s.id);
  getPastCompleted(schedules)
    .reverse()
    .forEach(sch => {
      switch (sch.type) {
        case schTypeKey(ScheduleType.DROP_OFF):
          sch.spaces.forEach(space => { emptySpaceIds = emptySpaceIds.filter(id => id !== space.id); });
          break;
        case schTypeKey(ScheduleType.PICK_UP):
          sch.spaces.forEach(space => emptySpaceIds.push(space.id));
          break;
        default:
          throw new Error();
      }
    });
  return bookedT2A
    .map(t2a => ({
      ...t2a,
      spaces: t2a.spaces.filter(s => emptySpaceIds.includes(s.id))
    }))
    .map(t2aToSpaceWithAvailability);
};
