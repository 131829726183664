import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import {
  IBuildingForDisplaying,
  IOwnerShortInfoDto,
  ISpaceWithAvailability
} from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import {
  extractBuildingDetails,
  extractFetchBuildingDetailsLoading,
  extractFetchSpaceAvailabilityLoading, extractOwner,
  extractRequestBookingLoading, extractSendMessageFromSpacesToOwnerLoading,
  extractSpaces,
  extractToggleFavoriteBuildingLoading, extractWriting
} from '@screens/NeedsDashboard/BuildingDetails/reducers';
import {
  fetchBuildingDetailsRoutine,
  fetchSpaceAvailabilityRoutine,
  requestBookingRoutine, sendMessageFromSpacesToOwnerRoutine, setWritingMessageFromSpacesToOwnerRoutine,
  toggleFavoriteBuildingRoutine
} from '@screens/NeedsDashboard/BuildingDetails/routines';
import { IBindingCallback1 } from '@models/Callbacks';
// import BuildingRating from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingRating';
import BuildingTags from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingTags';
import { IBuildingDetailsRequest } from '@screens/BuildingDetails/model/BuildingDetailsRequest';
import BookingSection from '@screens/BuildingDetails/components/BookingSection';
import { ISpacesAvailabilityRequest } from '@screens/BuildingDetails/model/SpacesAvailabilityRequest';
import { IBookingRequest } from '@screens/BuildingDetails/model/BookingRequest';
import ImageGalleryCarousel from '@screens/NeedsDashboard/BuildingDetails/components/ImageGalleryCarousel';
import OwnerEditButton from '@components/EditBuildingButtons/OwnerEditButton';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import WritingMessageModal from '@components/MessagesSection/WritingMessageModal';
import { extractCurrentUser } from '@screens/Authorization/reducers';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import PrimaryButton from '@components/NewDesign/Button/PrimaryButton';
import { AdminEditButton } from '@components/EditBuildingButtons/AdminEditButton';
import styles from './styles.module.scss';

export interface IBuildingDetailsProps extends IState, IActions {
}

interface IState {
  loading: boolean;
  building: IBuildingForDisplaying | undefined;
  owner?: IOwnerShortInfoDto;
  spaces: ISpaceWithAvailability[];
  spacesLoading: boolean;
  bookingLoading: boolean;
  favoriteLoading: boolean;
  writing: boolean;
  sendMessageLoading: boolean;
}

interface IActions {
  loadBuilding: IBindingCallback1<IBuildingDetailsRequest>;
  fetchSpaceAvailability: IBindingCallback1<ISpacesAvailabilityRequest>;
  requestBooking: IBindingCallback1<IBookingRequest>;
  toggleFavorite: IBindingCallback1<string>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
}

const BuildingDetails: React.FC<IBuildingDetailsProps> = (
  {
    loading, building, loadBuilding, spaces,
    fetchSpaceAvailability, spacesLoading, bookingLoading, requestBooking, toggleFavorite,
    favoriteLoading, setWriting, sendMessageLoading, sendMessage, writing, owner
  }
) => {
  const location = useLocation();
  const history = useHistory();
  const currentUser = useSelector(extractCurrentUser);
  const { building: buildingId } = parse(location.search);

  useEffect(() => {
    if (buildingId) {
      loadBuilding({ id: buildingId as string, from: new Date() });
    }
  }, [buildingId, loadBuilding]);

  return (
    <DashboardPageWrapper>
      <DashboardSectionWrapper>
        <div className={styles.top_buttons_container}>
          <SmallLinkButton
            to=""
            onClick={() => history.goBack()}
            left
          >
            Back to Dashboard
          </SmallLinkButton>
          <div className={styles.filler} />
          <OwnerEditButton
            ownerId={building?.ownerId}
            buildingId={buildingId as string}
          />
          <AdminEditButton buildingId={buildingId as string} />
          {building && building.ownerId !== currentUser?.id && (
            <PrimaryButton
              content="Write to owner"
              onClick={() => setWriting(true)}
              className={styles.write_to_owner_button}
            />
          )}
        </div>
        <BuildingHeaderInfo building={building} toggleLike={toggleFavorite} likeLoading={favoriteLoading} />
        {/* <BuildingRating loading={loading} building={building} /> */}
        <BuildingTags loading={loading} building={building} />
        <ImageGalleryCarousel
          className={styles.image_container}
          images={building?.gallery ?? []}
          loading={loading}
          building={building}
        />
        <BookingSection
          className={styles.booking_margin}
          loading={loading}
          spaces={spaces}
          fetchSpaceAvailability={fetchSpaceAvailability}
          spacesLoading={spacesLoading}
          requestBooking={requestBooking}
          bookingLoading={bookingLoading}
        />
        {writing && owner && (
          <WritingMessageModal
            onClose={() => setWriting(false)}
            onSend={sendMessage}
            sendLoading={sendMessageLoading}
            receiverId={owner.id}
            initValues={{ topic: building.buildingName }}
          />
        )}
      </DashboardSectionWrapper>
    </DashboardPageWrapper>
  );
};

const mapStateToProps: (state) => IState = state => ({
  loading: extractFetchBuildingDetailsLoading(state),
  building: extractBuildingDetails(state),
  spaces: extractSpaces(state),
  spacesLoading: extractFetchSpaceAvailabilityLoading(state),
  bookingLoading: extractRequestBookingLoading(state),
  favoriteLoading: extractToggleFavoriteBuildingLoading(state),
  sendMessageLoading: extractSendMessageFromSpacesToOwnerLoading(state),
  writing: extractWriting(state),
  owner: extractOwner(state)
});

const mapDispatchToProps: IActions = {
  loadBuilding: fetchBuildingDetailsRoutine,
  fetchSpaceAvailability: fetchSpaceAvailabilityRoutine,
  requestBooking: requestBookingRoutine,
  toggleFavorite: toggleFavoriteBuildingRoutine,
  sendMessage: sendMessageFromSpacesToOwnerRoutine,
  setWriting: setWritingMessageFromSpacesToOwnerRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetails);
