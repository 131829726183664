import React, { useCallback } from 'react';
import { visibleToSpecificHave } from '@helpers/authRules.helper';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import { HAVES_DASHBOARD_ENDPOINTS } from '@screens/HavesDashboard/Root/components/Routing/endpoints';

export interface IOwnerEditButtonProps {
  ownerId: string;
  buildingId: string;
}

const OwnerEditButton: React.FC<IOwnerEditButtonProps> = (
  { ownerId, buildingId }
) => {
  const Btn = useCallback(visibleToSpecificHave(ownerId)(
    () => (
      <SmallLinkButton
        to={HAVES_DASHBOARD_ENDPOINTS.BUILDING_EDITOR(buildingId)}
        right
      >
        Edit
      </SmallLinkButton>
    )
  ), [ownerId, buildingId]);
  return <Btn />;
};

export default OwnerEditButton;
