import React from 'react';
import { BookingStatus } from '@models/domain/BookingStatus';
import Timer from '@components/NewDesign/Timer';
import Caption1 from '@components/NewDesign/Typography/Caption/Caption1';
import PopupWrapper from '@components/NewDesign/PopupWrapper';
import { EXPIRATION_MINUTES } from '@screens/Checkout/containers/CheckoutPage';
import styles from './styles.module.scss';

export interface IBookingStatusLabelProps {
  status: BookingStatus;
  createdAt: string;
}

const bookingStatusToTitle = (status: BookingStatus): string => {
  switch (status) {
    case BookingStatus.PENDING:
      return 'PENDING';
    case BookingStatus.CONFIRMED:
      return 'ACTIVE';
    case BookingStatus.CANCELLED:
      return 'CANCELLED';
    case BookingStatus.FINISHED:
      return 'EXPIRED';
    case BookingStatus.RETRYING:
      return 'RETRYING';
    case BookingStatus.FAILED:
      return 'FAILED';
    default:
      return '';
  }
};

const bookingStatusToDescription = (status: BookingStatus): string => {
  switch (status) {
    case BookingStatus.PENDING:
      return 'You have a booking waiting to be finalized';
    case BookingStatus.CONFIRMED:
      return 'Booking has been made, and is currently active';
    case BookingStatus.CANCELLED:
      return 'Booking has not been completed';
    case BookingStatus.FINISHED:
      return 'Booking has been made, and is currently expired';
    case BookingStatus.RETRYING:
      return 'Booking has been made, but monthly payment has failed. Retrying to charge your payment method.';
    case BookingStatus.FAILED:
      return 'Booking has been made, but monthly payment has failed after '
        + 'multiple attempts to charge your payment method. ';
    default:
      return '';
  }
};

const BookingStatusLabel: React.FC<IBookingStatusLabelProps> = (
  { status, createdAt }
) => {
  const date = new Date(createdAt);
  date.setMinutes(date.getMinutes() + EXPIRATION_MINUTES);

  const content = status === BookingStatus.PENDING
    ? <Timer expirationDate={date} inline />
    : (
      <Caption1 className={styles[status]}>
        {bookingStatusToTitle(status)}
      </Caption1>
    );

  return (
    <PopupWrapper
      trigger={(
        <span>
          {content}
        </span>
      )}
      content={bookingStatusToDescription(status)}
    />
  );
};

export default BookingStatusLabel;
