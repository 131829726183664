import React from 'react';
import { connect } from 'react-redux';
import { IBookingDetailsForSchedulingDto } from '@screens/NeedsDashboard/DropOffAndPickUp/model/BookingDetailsDto';
import BookingsTable from '@screens/NeedsDashboard/DropOffAndPickUp/containers/BookingsTable';
import ConnectedBookingDetailsModal
  from '@screens/NeedsDashboard/DropOffAndPickUp/containers/ConnectedBookingDetailsModal';
import { BookingStatus } from '@models/domain/BookingStatus';
import { ENDPOINTS } from '@containers/Routing/endpoints';
import { setExpandedBookingRoutine } from '@screens/NeedsDashboard/DropOffAndPickUp/routines';
import { extractExpandedBooking } from '@screens/NeedsDashboard/DropOffAndPickUp/reducers';
import { IBindingCallback1 } from '@models/Callbacks';
import BookingStatusLabel from '@components/BookingStatusLabel';
import TableImportantContent from '@components/NewDesign/Typography/FormAndTable/TableImportantContent';
import { history } from '@helpers/history.helper';
import DashboardPageWrapper from '@components/NewDesign/DashboardPageWrapper';
import DashboardSectionWrapper from '@components/NewDesign/DashboardSectionWrapper';
import styles from './styles.module.scss';

interface IState {
  expandedBooking?: IBookingDetailsForSchedulingDto;
}

interface IActions {
  setExpandedBooking: IBindingCallback1<IBookingDetailsForSchedulingDto | undefined>;
}

interface IProps extends IState, IActions {
}

const isDisabled = (b: IBookingDetailsForSchedulingDto) => b.booking.status === BookingStatus.CANCELLED;

const DropOffAndPickUp: React.FC<IProps> = ({ expandedBooking, setExpandedBooking }) => {
  const handleNameClick = (event, booking) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(ENDPOINTS.BOOKING(booking.booking.id));
  };

  const buildingName = booking => (
    <div
      onClick={ev => handleNameClick(ev, booking)}
    >
      <TableImportantContent disableTranslation>
        {booking.building.buildingName}
      </TableImportantContent>
    </div>
  );

  const bookingStatusLabel = booking => (
    <BookingStatusLabel
      status={booking.booking.status}
      createdAt={booking.booking.createdAt}
    />
  );

  const spaceDetails = booking => {
    const t2a = booking.templatesToAmounts;
    return t2a.length > 1
      ? `${t2a.length} kinds of spaces`
      : t2a[0].spaceTemplate.alias;
  };

  const qty = booking => booking.templatesToAmounts.map(t2a => t2a.spaces.length)
    .reduce((prev, curr) => prev + curr).toString();

  const buildingAddress = booking => booking.building.location?.address || 'Unknown';

  return (
    <>
      <DashboardPageWrapper title="Bookings and Scheduling">
        <DashboardSectionWrapper>
          <BookingsTable
            tableProps={bookings => ({
              titles: ['Building Name', 'Status', 'Space Details', 'Qty', 'Address'],
              rows: bookings.map(b => ({
                id: b.booking.id,
                cells: [
                  { content: buildingName(b) },
                  { content: bookingStatusLabel(b) },
                  { content: spaceDetails(b) },
                  { content: qty(b) },
                  { content: buildingAddress(b) }
                ],
                rowProps: {
                  onClick: () => setExpandedBooking(b),
                  className: isDisabled(b) && styles.disabled
                }
              }))
            })}
          />
        </DashboardSectionWrapper>
      </DashboardPageWrapper>
      <ConnectedBookingDetailsModal setExpandedBooking={setExpandedBooking} expandedBooking={expandedBooking} />
    </>
  );
};
const mapStateToProps = state => ({
  expandedBooking: extractExpandedBooking(state)
});

const mapDispatchToProps = {
  setExpandedBooking: setExpandedBookingRoutine.fulfill
};

export default connect(mapStateToProps, mapDispatchToProps)(DropOffAndPickUp);
