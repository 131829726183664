import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Sticky from 'react-stickynode';
import ImageGallery from '@screens/BuildingDetails/components/ImageGallery';
import DescriptionSection from '@screens/BuildingDetails/components/DescriptionSection';
import ScrollToTopOnMount from '@components/ScrollToTop';
import {
  bookSpacesRoutine,
  fetchBuildingDetailsRoutine,
  fetchSpaceAvailabilityRoutine,
  sendMessageFromBrowseBuildingDetailsRoutine,
  setWritingMessageFromBrowseBuildingDetailsRoutine,
  toggleFavoriteBuildingRoutine,
  redirectWithBookingStateRoutine,
  clearCurrentBookingRoutine, toggleResetSelectedSpacesRoutine,
  loadAdditionalServicesWithAvailabilityRoutine } from '@screens/BuildingDetails/routines';
import { IBindingAction, IBindingCallback1 } from '@models/Callbacks';
import {
  extractAvailableSpacesLoading,
  extractBookSpacesLoading,
  extractBuildingDetails,
  extractBuildingDetailsLoading,
  extractBuildingDetailsSpaces,
  extractCurrentBooking,
  extractIsNeedToResetSelectedSpaces, extractLoadAdditionalServicesWithAvailabilityLoading,
  extractOwner,
  extractSendMessageFromBrowseBuildingDetailsLoading,
  extractToggleFavoriteBuildingLoading,
  extractWriting,
  extractAdditionalServicesWithAvailability } from '@screens/BuildingDetails/reducers';
import { IBuildingDetailsRequest } from '@screens/BuildingDetails/model/BuildingDetailsRequest';
import {
  IBuildingForDisplaying,
  IOwnerShortInfoDto,
  ISpaceWithAvailability
} from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { extractQuery } from '@screens/BrowseSpaces/reducers';
import { IDatesData } from '@screens/BrowseSpaces/model/QueryData';
import { ISpacesAvailabilityRequest } from '@screens/BuildingDetails/model/SpacesAvailabilityRequest';
import { IBookingRequest } from '@screens/BuildingDetails/model/BookingRequest';
import { ICurrentBooking } from '@screens/BuildingDetails/model/ICurrentBooking';
import { ISendMessageDto } from '@models/domain/message/SendMessageDto';
import WritingMessageModal from '@components/MessagesSection/WritingMessageModal';
import s from './styles.module.scss';

import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';

export interface IBuildingDetailsPageProps extends IState, IActions {
}

interface IState {
  loading: boolean;
  spacesLoading: boolean;
  building: IBuildingForDisplaying;
  currentBooking: ICurrentBooking;
  selectedDates: IDatesData;
  bookingLoading: boolean;
  spaces: ISpaceWithAvailability[];
  favoriteLoading: boolean;
  sendMessageLoading: boolean;
  writing: boolean;
  owner?: IOwnerShortInfoDto;
  isNeedToResetSelectedSpaces: boolean;
  services: IAdditionalServiceWithAvailability[];
  servicesLoading?: boolean;
}

interface IActions {
  fetchDetails: IBindingCallback1<IBuildingDetailsRequest>;
  fetchSpaceAvailability: IBindingCallback1<ISpacesAvailabilityRequest>;
  requestBooking: IBindingCallback1<IBookingRequest>;
  redirectBooking: IBindingCallback1<ICurrentBooking>;
  clearCurrentBooking: IBindingAction;
  toggleFavorite: IBindingCallback1<string>;
  sendMessage: IBindingCallback1<ISendMessageDto>;
  setWriting: IBindingCallback1<boolean>;
  toggleResetSelectedSpaces: IBindingAction;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
}

export interface IPassedState {
  buildingName?: string;
}

const BuildingDetailsPage: React.FC<IBuildingDetailsPageProps> = (
  { fetchDetails, loading, building, spaces, selectedDates, fetchSpaceAvailability,
    spacesLoading, requestBooking, redirectBooking, currentBooking, clearCurrentBooking, bookingLoading, toggleFavorite,
    favoriteLoading, sendMessage, sendMessageLoading, setWriting, writing, owner,
    isNeedToResetSelectedSpaces, toggleResetSelectedSpaces, services, loadAdditionalServicesList, servicesLoading }
) => {
  const { id } = useParams<{id: string}>();
  const passedState: IPassedState | undefined = useHistory().location.state;

  const tempDates = {
    startingDate: selectedDates?.startingDate ?? new Date(),
    endingDate: selectedDates?.endingDate
  };

  useEffect(() => {
    fetchDetails({ id, from: tempDates.startingDate, to: tempDates.endingDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDetails, id]);

  useEffect(() => {
    if (id) {
      loadAdditionalServicesList({
        buildingId: id,
        scheduledOn: new Date()
      });
    }
  }, [id, loadAdditionalServicesList]);

  return (
    <>
      <ScrollToTopOnMount />
      <div className={`content_wrapper ${s.root}`}>
        <div id="details__section" className={s.container}>
          <ImageGallery
            classNames={s.container__images}
            images={building?.gallery ?? []}
            loading={loading}
            building={building}
          />
          <Sticky
            top="#navbar"
            bottomBoundary="#details__section"
          >
            <DescriptionSection
              className={s.container__description}
              previewBuildingName={passedState?.buildingName}
              loading={loading}
              building={building?.id === id && building}
              spaces={spaces}
              selectedDates={tempDates}
              fetchSpaceAvailability={fetchSpaceAvailability}
              spacesLoading={spacesLoading}
              requestBooking={requestBooking}
              redirectBooking={redirectBooking}
              currentBooking={currentBooking}
              clearCurrentBooking={clearCurrentBooking}
              bookingLoading={bookingLoading}
              likeLoading={favoriteLoading}
              toggleLike={toggleFavorite}
              setWriting={setWriting}
              isNeedToResetSelectedSpaces={isNeedToResetSelectedSpaces}
              toggleResetSelectedSpaces={toggleResetSelectedSpaces}
              services={services}
              servicesLoading={servicesLoading}
            />
          </Sticky>
        </div>
        {writing && owner && (
          <WritingMessageModal
            onClose={() => setWriting(false)}
            onSend={sendMessage}
            sendLoading={sendMessageLoading}
            receiverId={owner.id}
            initValues={{ topic: building.buildingName }}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps: IActions = {
  fetchDetails: fetchBuildingDetailsRoutine,
  fetchSpaceAvailability: fetchSpaceAvailabilityRoutine,
  requestBooking: bookSpacesRoutine,
  redirectBooking: redirectWithBookingStateRoutine,
  toggleFavorite: toggleFavoriteBuildingRoutine,
  sendMessage: sendMessageFromBrowseBuildingDetailsRoutine,
  setWriting: setWritingMessageFromBrowseBuildingDetailsRoutine.fulfill,
  clearCurrentBooking: clearCurrentBookingRoutine.fulfill,
  toggleResetSelectedSpaces: toggleResetSelectedSpacesRoutine.fulfill,
  loadAdditionalServicesList: loadAdditionalServicesWithAvailabilityRoutine
};

const mapStateToProps: (state) => IState = state => ({
  loading: extractBuildingDetailsLoading(state),
  building: extractBuildingDetails(state),
  currentBooking: extractCurrentBooking(state),
  spaces: extractBuildingDetailsSpaces(state),
  selectedDates: extractQuery(state).dates,
  spacesLoading: extractAvailableSpacesLoading(state),
  bookingLoading: extractBookSpacesLoading(state),
  favoriteLoading: extractToggleFavoriteBuildingLoading(state),
  sendMessageLoading: extractSendMessageFromBrowseBuildingDetailsLoading(state),
  writing: extractWriting(state),
  owner: extractOwner(state),
  isNeedToResetSelectedSpaces: extractIsNeedToResetSelectedSpaces(state),
  services: extractAdditionalServicesWithAvailability(state),
  servicesLoading: extractLoadAdditionalServicesWithAvailabilityLoading(state)
});

export default connect(mapStateToProps, mapDispatchToProps)(BuildingDetailsPage);
