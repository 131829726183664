import React from 'react';
import { Label, LabelProps } from 'semantic-ui-react';
import styles from './styles.module.scss';

const LabelWrapper: React.FC<LabelProps> = props => (
  <div className={styles.label_wrapper}>
    <Label {...props} />
  </div>
);
export default LabelWrapper;
