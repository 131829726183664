import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import s from '@screens/BookingCheckout/containers/BookingCheckoutPage/BookingDetailsCheckoutStep/styles.module.scss';
// import Rating from '@screens/BookingCheckout/components/BookingDetails/Rating';
import { IBookingWithSpaces } from '@screens/BookingCheckout/model/BookingCheckout';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import { IBindingCallback1 } from '@models/Callbacks';
import {
  extractAdditionalServicesWithAvailability, extractAdditionalServicesWithAvailabilityLoading,
  extractBookingData,
  extractBuildingData,
  extractToggleFavoriteLoading
} from '@screens/BookingCheckout/reducers';
import {
  createAdditionalServicesOrderRoutine,
  loadAdditionalServicesWithAvailabilityRoutine,
  toggleFavoriteRoutine
} from '@screens/BookingCheckout/routines';
import BookingSpacesSummary from '@screens/BookingCheckout/components/BookingDetails/BookingSpacesSummary';
import BuildingHeaderInfo from '@screens/BookingCheckout/components/BookingDetails/BuildingHeaderInfo';
import { bookingToPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import { getDefaultImageFromBuildingType } from '@helpers/image.placeholder.helper';
import ZoomableImage from '@screens/BuildingDetails/components/ZoomableImage';
import BuildingTags from '@screens/BuildingDetails/components/DescriptionSection/components/BuildingTags';
import SmallLinkButton from '@components/NewDesign/Button/SmallLinkButton';
import AdditionalServicesSection from '@components/NewDesign/AdditionalServicesSection';
import { IAdditionalServiceWithAvailability } from '@models/domain/additional_service/AdditionalServiceWithAvailability';
import { ILoadAdditionalServiceWithAvailabilityRequest } from '@models/domain/additional_service/LoadAdditionalServiceWithAvailabilityRequest';
import { IMakeOrderRequest } from '@models/domain/additional_service/MakeOrderRequest';
import MetaTags from '@components/MetaTags';
import NeedNotes from '@components/NewDesign/NeedNotes';
import TransactionsBlock from './TransactionsBlock';
import styles from './styles.module.scss';

export type IBookingDetailsPageProps = IState & IActions;

interface IState {
  bookingWithSpaces: IBookingWithSpaces;
  building: IBuildingForDisplaying;
  loadingFavorite: boolean;
  servicesList: IAdditionalServiceWithAvailability[];
  servicesListLoading: boolean;
}

interface IActions {
  toggleFavorite: IBindingCallback1<string>;
  loadAdditionalServicesList: IBindingCallback1<ILoadAdditionalServiceWithAvailabilityRequest>;
  createAdditionalServicesOrder: IBindingCallback1<IMakeOrderRequest>;
}

const BookingDetailsPage: React.FC<IBookingDetailsPageProps> = (
  {
    building, toggleFavorite, bookingWithSpaces, loadingFavorite,
    servicesList, servicesListLoading, loadAdditionalServicesList, createAdditionalServicesOrder
  }
) => {
  const history = useHistory();

  const periods = useMemo(
    () => bookingToPeriods(bookingWithSpaces.booking),
    [bookingWithSpaces.booking]
  );

  return (
    <div className={`content_wrapper ${s.details__container}`}>
      <MetaTags title={`Booking | ${building.buildingName}`} withSuffix description={building.description} />
      <div className={s.details__left}>
        <ZoomableImage
          src={building?.avatar || getDefaultImageFromBuildingType(building?.type)}
        />
      </div>
      <div className={s.details__right}>
        <div className={s.top_buttons_container}>
          <SmallLinkButton
            to=""
            onClick={() => history.goBack()}
            left
          >
            Back to Dashboard
          </SmallLinkButton>
        </div>
        <BuildingHeaderInfo
          building={building}
          likeLoading={loadingFavorite}
          toggleLike={toggleFavorite}
        />
        {/* <Rating />*/}
        <BuildingTags building={building} />
        <BookingSpacesSummary
          spaces={bookingWithSpaces.spaces || []}
          booking={bookingWithSpaces.booking}
          periods={periods}
        />
        <NeedNotes
          notesText={bookingWithSpaces.booking.note}
          marginTop
        />
        <TransactionsBlock
          bookingId={bookingWithSpaces.booking.id}
          className={styles.transactions_section}
        />
        <AdditionalServicesSection
          booking={bookingWithSpaces?.booking}
          buildingId={building.id}
          className={styles.servicesWrapper}
          servicesList={servicesList}
          servicesListLoading={servicesListLoading}
          loadAdditionalServicesList={loadAdditionalServicesList}
          createAdditionalServicesOrder={createAdditionalServicesOrder}
        />
      </div>
    </div>
  );
};

const mapStateToProps: (state) => IState = state => ({
  building: extractBuildingData(state),
  bookingWithSpaces: extractBookingData(state),
  loadingFavorite: extractToggleFavoriteLoading(state),
  servicesList: extractAdditionalServicesWithAvailability(state),
  servicesListLoading: extractAdditionalServicesWithAvailabilityLoading(state)
});

const mapDispatchToProps: IActions = {
  toggleFavorite: toggleFavoriteRoutine,
  loadAdditionalServicesList: loadAdditionalServicesWithAvailabilityRoutine,
  createAdditionalServicesOrder: createAdditionalServicesOrderRoutine
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetailsPage);
