import React from 'react';
import styles from './styles.module.scss';
import { IBuildingForDisplaying } from '@screens/BuildingDetails/model/BuildingDetailsResponse';
import HostedByLabel from '@screens/BookingCheckout/components/BookingDetails/HostedByLabel';
import BuildingTitle from '@screens/BookingCheckout/components/BookingDetails/BuildingTitle';
import BuildingAddress from '@screens/BookingCheckout/components/BookingDetails/BuildingAddress';
import BuildingDescription from '@screens/BookingCheckout/components/BookingDetails/BuildingDescription';
import { IBindingCallback1 } from '@models/Callbacks';
import LikeButton from '@components/LikeButton';

export interface IBuildingHeaderInfoProps {
  building: IBuildingForDisplaying;
  toggleLike?: IBindingCallback1<string>;
  likeLoading?: boolean;
  hideMap?: boolean;
}

const BuildingHeaderInfo: React.FC<IBuildingHeaderInfoProps> = (
  { building, toggleLike, likeLoading, hideMap }
) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div>
        <HostedByLabel companyName={building?.companyName} />
        <BuildingTitle buildingName={building?.buildingName} />
        <BuildingAddress building={building} hideMap={hideMap} />
      </div>
      {toggleLike && (
        <LikeButton
          liked={building?.favorite}
          size="massive"
          loading={likeLoading}
          onToggleLike={() => toggleLike(building?.id)}
        />
      )}
    </div>
    <BuildingDescription text={building?.description} />
  </div>
);

export default BuildingHeaderInfo;
