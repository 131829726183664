import React from 'react';
import { ISpaceBooked } from '@screens/BookingCheckout/model/BookingCheckout';
import BookingOneSpaceSummary from '@screens/BookingCheckout/components/BookingDetails/BookingOneSpaceSummary';
import { IBriefBookingDto } from '@models/domain/Booking';
import { IBookingPaymentPeriods } from '@screens/BookingCheckout/helpers/bookingPeriods.helper';
import styles from './styles.module.scss';

export interface IBookingSpacesSummaryProps {
  spaces: ISpaceBooked[];
  booking: IBriefBookingDto;
  periods: IBookingPaymentPeriods;
}

const BookingSpacesSummary: React.FC<IBookingSpacesSummaryProps> = (
  { spaces, booking, periods }
) => (
  <div className={styles.container}>
    {spaces.map(sp => (
      <BookingOneSpaceSummary key={sp.id} space={sp} booking={booking} periods={periods} />
    ))}
  </div>
);

export default BookingSpacesSummary;
