import React from 'react';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import { IBindingAction } from '@models/Callbacks';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import styles from './styles.module.scss';

export interface ISliderStepProps {
  index?: number;
  titleComponent: JSX.Element;
  active?: boolean;
  disabled?: boolean;
  indexClassName?: string;
  activeIconName?: SemanticICONS;
  inactiveIconName?: SemanticICONS;
  onClick?: IBindingAction;
  containerClassName?: string;
  contentClassName?: string;
  headerClassName?: string;
}

const Slider: React.FC<ISliderStepProps> = ({
  index,
  active,
  disabled,
  onClick,
  children,
  titleComponent,
  containerClassName,
  contentClassName,
  headerClassName,
  indexClassName,
  activeIconName = 'chevron right',
  inactiveIconName = 'chevron down'
}) => {
  const handleClick = () => {
    if (disabled) {
      return;
    }
    onClick();
  };

  return (
    <div className={classNames(containerClassName)}>
      <div
        className={classNames(styles.header, headerClassName, disabled && styles.header_disabled)}
        onClick={handleClick}
      >
        {typeof index === 'number' && <div className={indexClassName}>{index}</div>}
        {titleComponent}
        <Icon className={styles.arrow} name={active ? activeIconName : inactiveIconName} />
      </div>
      <div className={classNames(
        contentClassName,
        active ? styles.content_active : styles.content_disabled
      )}
      >
        {children}
      </div>
    </div>
  );
};

export { Slider };
